.bg {
    width: 100%;
    height: fit-content;
    height: 100%;
    position: absolute;
    background-image: url('../../images/bgCar2.jpeg');
    background-size: cover;
    background-position:center;
    opacity: 0.6;
    display: none;
}
.bord {
    position: relative;
}
.backgroundVideo {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.6;
}
@media(max-width: 767px){
    .bg {
        background-image: url('../../images/bgCar2.jpeg');
    }
}

@media (max-aspect-ratio: 9/12) {
    .backgroundVideo {
        display: none;
    }
    .bg {
        display: block;
    }
    .displayDesktop {
        display: none;
    }
    .footerMobile {
        width: 100%;
    }
}